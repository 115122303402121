import React, { useEffect } from 'react'
import '.././global.css'
import '.././movil.css'
import Layout from "../../share/layout";
import '../../../styles/font-awesome-4.7.0/css/font-awesome.css'
import Image0 from '../../../images/sonidosaudiovisuales/face.png'
import Image1 from '../../../images/sonidosaudiovisuales/artista.png'
import Image2 from '../../../images/sonidosaudiovisuales/back.png'

const Emilio = () => {
    useEffect(() => {
        document.body.classList.add('body-sonidos-visuales');
        return function cleanup() {
            document.body.classList.remove('body-sonidos-visuales');
        };
    }, [])
    return (
        <Layout
            title="Geografías | UPC Cultural"
            metaTitle="Geografías | UPC Cultural"
            description="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos. "
            subtitile="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            metaDescripcin="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            imgurl="//images.ctfassets.net/bv5rnjawitjg/E9aFHf11TxaEIX6JuU3vL/550f80daa53881d13f10dc95466806e2/800_x_800-1.png?h=300"
            keywords="Multimedia, Audiovisual, Luis Alvarado, Emilio Le Roux, Vered Engelhard, Luis Sanz, Paola Torres Núñez del Prado, Jonathan Castro Alejos, Lucía Beaumont."
        >
            <div className="clearfix" id="page">

                <section id="section-content" className="section-content">
                    <div className="region-inner region-content-inner">
                        <div className="face"><a href="https://www.facebook.com/upccultural1/" target="_blank"><img src={Image0} /></a></div>
                        <div id="page3" className="page page3 pdetalles">
                            <div className="inner">
                                <span className="bg1"></span>
                                <span className="bg2"></span>
                                <span className="bglogo2"><span className="logoimg"></span></span>
                                <div className="row">
                                    <div className="col cleft">
                                        <div className="video">
                                            <iframe id="video8" src="https://player.vimeo.com/video/726089088?h=b1b21442a3&color=606060&title=0&byline=0&portrait=0" width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                                        </div>
                                    </div>
                                    <div className="col cright">
                                        <div className="artista"><img src={Image1} /></div>
                                        <div className="titulo">
                                            <div className="nombre"><b>Lucía Beaumont</b></div>
                                            <div className="obra"><b>Obra:</b> Arena y Ceniza</div>
                                        </div>
                                        <div className="detalle">
                                            <p>Arena y Ceniza</p>
                                            <p className="rleft">Nombres femeninos</p>
                                            <p>1. Materia compuesta por polvo y pequeños granos de mineral de color gris que quedan como residuo de una combustión completa y se acumulan en playas.<br />
                                                2. Mujeres de poder y hechas para dirigir y mandar. Muy aferradas a sus ideas y principios, se muestran activas y combatientes por todo aquello que les interesa.</p>
                                            <p>Biografía<br />
                                                Lucía Beaumont (Lima, 1996) es artista. Licenciada en Arte con mención en Pintura por la Facultad de Arte y Diseño de la PUCP, Lima, y Diplomada en Dirección de Arte por el Instituto Superior Centro de la Imagen, Lima. Ha participado en la Exposición “Artiviral: Videoarte peruano reciente”, Centro Cultural PUCP (2021). Ha sido merecedora del Premio de la Crítica, Primer puesto al proyecto de fin de carrera de la especialidad de Pintura, Facultad de Arte y Diseño, PUCP (2020), el Premio “Winternitz” de la Facultad de Arte y Diseño de la PUCP a la excelencia académica (2019-2020).<br />
                                                <a target="_blank" href="https://soundcloud.com/luciabeau">https://soundcloud.com/luciabeau</a></p>
                                        </div>
                                        <div className="btnback" >
                                            <a data-page="p0" href='/galeria/geografias'><img src={Image2} /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default Emilio